const SwipeStyles = {
    box: {
      borderColor: "#ffffff",
      backgroundColor: "#ffffff",
      borderRadius: "15px",
      color: "#ed1a84",
      border: "none",
      outline: "none",
  
      display: "flex",
      width: "100%",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      transition: "opacity 0.3s ease-in-out", // Transition for smooth fading
      fontFamily: "Gotham, sans-serif",
      fontWeight: "lighter",
      justifyContent: "space-evenly",
    },
    header: {
      fontSize: "20px",
      marginBottom: 0,
      color: "#ed1a84",
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
      fontWeight: "bold",
    },
    text: {
      fontSize: "16px",
      marginBottom: 0,
      color: "#ed1a84",
      padding: 20,
    },
    image: {
      maxWidth: "100%",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      margin: "auto",
      padding: 10,
      maxWidth: "1000px",
    },
    move_icon: {
      display: "inline-block",
      width: "10px",
      height: "10px",
      margin: "0 5px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    arrow:{
        background: "none",
        border: "none",
        cursor: "pointer",
        outline: "none",
        position: "absolute",
        bottom: "0px",
        right: "0px", // Just left of the image
      }
  };

export {SwipeStyles}