import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import cards from "../images/landingpage/landingpage.png";
import cards_mobile from "../images/landingpage/landingpagemobile.png";
import logo from "../images/landingpage/logo.png";
import { PurchaseModuleSimple } from "./purchase_modules";
import { NavLink } from "react-router-dom";
import { isMobile } from "react-device-detect";
import "photoswipe/dist/photoswipe.css";
import { SwipeModule, GalleryInsert } from "./landing_page";
import gif from "../images/mmcard.png";

function LandingPage() {
  const [back_img, setBackImg] = useState(cards);
  const [posX, setPosX] = useState("50%");
  const [posY, setPosY] = useState("73.2%");
  const [widthMob, setWidthMob] = useState("");
  const [mobPad, setMobPad] = useState("0");
  const [rulesSize, setRulesSize] = useState("30%");
  const [mPaddingBot, setMPaddingBot] = useState(50);

  const [positionX, setPositionX] = useState(10); // Horizontal position
  const [positionY, setPositionY] = useState(25); // Vertical position (default center)
  const [rotation, setRotation] = useState(0);
  const [directionX, setDirectionX] = useState(1); // 1 for right, -1 for left
  const [directionY, setDirectionY] = useState(1); // 1 for down, -1 for up

  useEffect(() => {
    const interval = setInterval(() => {
      // Update horizontal position
      setPositionX((prev) => {
        if (prev >= 35) {
          setDirectionX(-1); // Change direction to left
        } else if (prev <= 0) {
          setDirectionX(1); // Change direction to right
        }
        return prev + directionX * 3; // Change the bounce speed
      });

      // Update vertical position
      setPositionY((prev) => {
        if (prev >= 40) {
          setDirectionY(-1); // Change direction to up
        } else if (prev <= 15) {
          setDirectionY(1); // Change direction to down
        }
        return prev + directionY; // Change the bounce speed
      });

      setRotation((prev) => prev + 4); // Rotate image
    }, 30); // Change the interval to control animation speed

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [directionX]);

  useEffect(() => {
    if (isMobile) {
      setBackImg(cards_mobile);
      setPosX("165%");
      setPosY("50%");
      setWidthMob("80%");
      setMobPad(500);
      setRulesSize("80%");
      setMPaddingBot(100);
    }
  }, []);

  return (
    <div
      style={{ backgroundColor: "#f7c9e5", height: "100px", justify: "flex" }}
    >
      <Helmet>
        <title>LandingPage - roostgame.com</title>
        <meta name="description" content="Landing Page for Roost" />
      </Helmet>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f7c9e5",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            maxWidth: "1500px",
            margin: "0 auto",
          }}
        >
          <img
            src={back_img}
            alt=""
            style={{
              flex: 1,
              width: "100%",
              height: "100%",
              resizeMode: "contain",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: posX,
              left: posY,
              transform: "translate(-50%, -50%)",
              color: "black",
              fontSize: "24px",
              fontFamily: "Gotham, sans-serif",
              fontWeight: "lighter",
              zIndex: 1, // Ensure text is above the image
              width: widthMob,
            }}
          >
            <img
              src={logo}
              alt=""
              style={{
                flex: 1,
                width: "100%",
                height: "100%",
                resizeMode: "contain",
              }}
            />
            <p
              style={{
                paddingTop: 10,
                color: "#ed1a84",
                margin: 0,
                paddingBottom: 10,
              }}
            >
              birds, memory, strategy, speed, alliances and more <b>birds</b>{" "}
            </p>
            <PurchaseModuleSimple />
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f7c9e5",
            marginTop: mobPad,
          }}
        />
        {isMobile ? (
          <>
              <div
                style={{
                  width: "100%",
                  height: "10vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start", // Align items to the top
                  position: "relative",
                }}
              >
                <img
                  src={gif}
                  style={{
                    top: `${positionX}%`,
                    left: `${positionY}%`,
                    position: "absolute",
                    transform: `rotate(${rotation}deg)`,
                    transition: "top 0.1s",
                    width: "200px",
                    maxHeight: "100%",
                    objectFit: "contain",
                    backgroundColor: "transparent",
                  }}
                  alt="Description of GIF"
                />
              </div>
          </>
        ) : (
          <>
            <div></div>
          </>
        )}
        <div
          style={{
            backgroundColor: "#f7c9e5",
            height: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
        <SwipeModule />
        <div
          style={{
            backgroundColor: "#f7c9e5",
            height: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 40,
          }}
        ></div>
        <div style={{ backgroundColor: "#f7c9e5", height: "20px" }} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: 20,
            width: "100%",
          }}
        >
          <div
            style={{
              padding: 10,
              justifyContent: "center",
              fontFamily: "Gotham, sans-serif",
              fontWeight: "lighter",
              fontSize: "26px",
              color: "#ed1a84",
              alignItems: "center",
            }}
          >
            Featured Photos
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              paddingBottom: mPaddingBot,
            }}
          >
            <GalleryInsert />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
