import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

function SlideButton({ link, button_text, bold_text }) {
    if (!link) {
      console.error('Link is not defined or empty');
      return null; // Prevent rendering if link is missing
    }
  
    return (
      <div>
        <NavLink
          className="nav-link"
          to={link}
          style={{
            textAlign: "center",
            display: "flex",
            textAlignVertical: "center",
            width: "100%",
          }}
        >
          <button
            style={{
              backgroundColor: "#ed1a84",
              color: "#ffffff",
              borderRadius: "15px",
              border: "none",
              outline: "none",
              padding: 20,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontFamily: "Gotham, sans-serif",
                fontWeight: "lighter",
                fontSize: "16px",
                marginBottom: 0,
              }}
            >
              {button_text}{" "}<b>{bold_text}</b>
            </p>
          </button>
        </NavLink>
      </div>
    );
  }

export default SlideButton;
