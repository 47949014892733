import React, { Text, useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { FaGamepad } from "react-icons/fa";
import { GiDirectorChair, GiCardRandom } from "react-icons/gi";
import { BiTestTube, BiFirstPage } from "react-icons/bi";
import { Helmet } from "react-helmet";
import { font_families, colours } from "./styles";
import "./ShopifyBuyButton.css";

const ShopifyBuyButton = ({
  domain,
  storefrontAccessToken,
  productId,
  nodeId,
}) => {
  useEffect(() => {
    const scriptURL =
      "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";

    const loadScript = () => {
      const script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      document.head.appendChild(script);
      script.onload = ShopifyBuyInit;
    };

    const ShopifyBuyInit = () => {
      const client = window.ShopifyBuy.buildClient({
        domain,
        storefrontAccessToken,
      });

      window.ShopifyBuy.UI.onReady(client).then((ui) => {
        ui.createComponent("product", {
          id: productId,
          node: document.getElementById(nodeId),
          moneyFormat: "%24%7B%7Bamount%7D%7D",
          options: {
            product: {
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "calc(25% - 20px)",
                    "margin-left": "20px",
                    "margin-bottom": "50px",
                  },
                },
                title: {
                  "font-weight": "normal",
                  "font-family": "Roberto, sans-serif",
                  "font-size": "20px",
                  color: "#000000",
                },
                button: {
                  "font-size": "14px",
                  "font-family": "Roberto, sans-serif",
                  "padding-top": "17px",
                  "padding-bottom": "17px",
                  color: "#ffffff",
                  ":hover": {
                    color: "#ffffff",
                    "background-color": "#D80068",
                  },
                  "background-color": "#EC2383",
                  ":focus": {
                    "background-color": "#D80068",
                  },
                  "border-radius": "40px",
                  "padding-left": "53px",
                  "padding-right": "53px",
                },
                quantityInput: {
                  "font-size": "18px",
                  "padding-top": "17px",
                  "padding-bottom": "17px",
                },
                price: {
                  "font-family": "Roberto, sans-serif",
                  "font-size": "14px",
                  color: "#000000",
                },
                compareAt: {
                  "font-family": "Roberto, sans-serif",
                  "font-size": "15px",
                  color: "#29cfcf",
                },
                unitPrice: {
                  "font-family": "Roberto, sans-serif",
                  "font-size": "15px",
                  color: "#29cfcf",
                },
              },
              buttonDestination: "modal",
              contents: {
                options: false,
              },
              text: {
                button: "View product",
              },
            },
            productSet: {
              styles: {
                products: {
                  "max-width": "calc(25% - 20px)",
                  "margin-left": "20px",
                  "margin-bottom": "50px",
                },
              },
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
                button: false,
                buttonWithQuantity: true,
              },
              styles: {
                product: {
                  "max-width": "100%",
                  "margin-left": "0px",
                  "margin-bottom": "0px",
                },
                button: {
                  "font-size": "18px",
                  "padding-top": "14px",
                  "padding-bottom": "9px",
                  "background-color": "#D80068",
                  "border-radius": "40px",
                  "padding-left": "53px",
                  "padding-right": "53px",
                  ":hover": {
                    "background-color": "#D80068",
                  },
                  ":focus": {
                    "background-color": "#D80068",
                  },
                },
                title: {
                  "font-family": "Roberto, sans-serif",
                  "font-weight": "bold",
                  "font-size": "27px",
                  color: "#000000",
                },
                price: {
                  "font-family": "Roberto, sans-serif",
                  "font-weight": "normal",
                  "font-size": "17px",
                  color: "#000000",
                },
                compareAt: {
                  "font-family": "Roberto, sans-serif",
                  "font-weight": "normal",
                  "font-size": "14px",
                  color: "#000000",
                },
                unitPrice: {
                  "font-family": "Roberto, sans-serif",
                  "font-weight": "normal",
                  "font-size": "14px",
                  color: "#000000",
                },
                description: {
                  color: "#000000",
                },
              },
              text: {
                button: "Add to cart",
              },
            },
            modal: {
              styles: {
                modal: {
                  "background-color": "#f7c9e5",
                },
              },
            },
            cart: {
              styles: {
                button: {
                  "font-size": "18px",
                  "padding-top": "17px",
                  "padding-bottom": "17px",
                  "background-color": "#EC2383",
                  "border-radius": "40px",
                  ":hover": {
                    "background-color": "#D80068",
                  },
                  ":focus": {
                    "background-color": "#D80068",
                  },
                },
              },
              text: {
                total: "Subtotal",
                button: "Checkout",
              },
              contents: {
                note: true,
              },
            },
            toggle: {
              styles: {
                toggle: {
                  "background-color": "#EC2383",
                  ":hover": {
                    "background-color": "#D80068",
                  },
                  ":focus": {
                    "background-color": "#D80068",
                  },
                },
                count: {
                  "font-size": "18px",
                },
              },
            },
          },
        });
      });
    };

    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
  }, [domain, storefrontAccessToken, productId, nodeId]);

  return <div id={nodeId}></div>;
};

const RoostItem = ({
  rec_domain,
  rec_storefrontAccessToken,
  rec_productId,
  rec_nodeId,
}) => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#E8A4C3",
        borderRadius: "20px",
        padding: "20px",
        boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
        maxWidth: "400px",
        width: "100%",
        textAlign: "center",
        margin: 10,
      }}
    >
      <div className="product">
        <ShopifyBuyButton
          domain={rec_domain}
          storefrontAccessToken={rec_storefrontAccessToken}
          productId={rec_productId}
          nodeId={rec_nodeId}
        />
      </div>
    </div>
  );
};

function Shop() {
  const [posts, setPosts] = useState([]);
  const [logoSize, setLogoSize] = useState("50%");
  const [shapeSize, setShapeSize] = useState("50%");
  const [dir, setDir] = useState("row");

  useEffect(() => {
    if (isMobile) {
      setLogoSize("60%");
      setShapeSize("100%");
      setDir("column");
    }
  }, []);

  return (
    <div
      className="contact"
      style={{ paddingBottom: 100, backgroundColor: "#f7c9e5", display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}
    >
      <Helmet>
        <title>Supporters - roostgame.com</title>
        <meta
          name="description"
          content="All supporters of the Roost Kickstarter Launch"
        />
      </Helmet>
      <div style={{ width: "100%" }}>
        <div
          style={{
            paddingLeft: 0,
          }}
        >
          <div
            style={{
              paddingBottom: 100,
              flexDirection: "row",
              paddingTop: 20,
              fontFamily: "Gotham, sans-serif",
              fontSize: "40px",
              fontWeight: "lighter",
              color: "#ed1a84",
            }}
          >
            <h1><b>Our Games</b></h1>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: dir,
              }}
            >
              <RoostItem
                rec_domain="9bae23-23.myshopify.com"
                rec_storefrontAccessToken="8cf9982a6baef5b25406229e691f27e9"
                rec_productId="8523599479000"
                rec_nodeId="product-component-1724549626246"
              />
              <RoostItem
                rec_domain="9bae23-23.myshopify.com"
                rec_storefrontAccessToken="8cf9982a6baef5b25406229e691f27e9"
                rec_productId="8523611537624"
                rec_nodeId="product-component-1724551316802"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shop;
