import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import pink_k from "../../images/landingpage/chicken_white.png";
import { FaFeather } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import "./landingpage.css";

const myAPI = "mailchimp";

function PurchaseModuleSimple() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [classname, setClassname] = useState("jumping-image");
  useEffect(() => {
    if (isMobile) {
      setClassname("jumping-image-phone");
    }
  }, []);

  const addEmail = () => {
    if (email === "") {
      setError("Please enter an email address");
      return;
    }
    const data = {
      firstName: "Landing",
      lastName: "Page",
      email: email,
      refer: "",
    };
    API.post(myAPI, "/mailchimp", { body: data })
      .then((response) => {
        console.log(response);
        setError("Thank you for joining the waitlist!");
      })
      .catch((error) => {
        setError("There was an error with joining the waitlist");
      });
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{ padding: 20 }}>
        {/* <img
          src={kickstarter}
          alt=""
          style={{
            flex: 1,
            height: "30px",
            resizeMode: "contain",
            paddingBottom: 10,
          }}
        /> */}

        <p
          style={{
            fontSize: "16px",
            paddingBottom: 5,
            marginBottom: 0,
            color: "#ed1a84",
          }}
        >
          Sign up now to join the <b>Roost Community</b>. Find out first about
          expansions, giveways, free cards and things we're working on.
        </p>

        <div
          style={{
            marginBottom: 0,
            display: "flex",
            width: "100%",
            height: "50px",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              alignItems: "center",
              height: "100%",
              paddingRight: 5,
            }}
          >
            <label
              style={{
                margin: 0,
                width: "100%",
                height: "100%",
                padding: 0,
                display: "flex",
              }}
            >
              <input
                type="text"
                value={email}
                onChange={handleChangeEmail}
                placeholder={email === "" ? "your@email.com" : ""}
                style={inputStyle}
              />
            </label>
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "20%",
            }}
          >
            <button onClick={addEmail} style={buttonStyle}>
              <FaFeather
                className="bird-icon"
                style={{
                  fontSize: "1.5em",
                  transition: "transform 0.3s",
                }}
              />{" "}
            </button>
          </div>
        </div>
        <div>
          <a
            href="/shop"
            style={{
              fontFamily: "Gotham, sans-serif",
              fontWeight: "lighter",
              fontSize: "16px",
              color: "#ed1a84",
              marginTop: 10,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              marginBottom: 0,
            }}
          >
            <button
              style={{
                borderColor: "#ffffff",
                backgroundColor: "#ed1a84",
                borderRadius: "15px",
                color: "#ffffff",
                border: "none",
                outline: "none",
                justifyContent: "center",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "50px",
                width: "100%",
              }}
            >
              <p
                style={{
                  marginBottom: 0,
                  paddingTop: 5,
                  fontFamily: "Gotham, sans-serif",
                  fontWeight: "lighter",
                  fontSize: "16px",
                }}
              >
                Check out <b>Roost</b>
              </p>

              <img
                src={pink_k}
                className={classname}
                style={{
                  height: "100%",
                  width: "auto",
                  padding: 7,
                  marginLeft: 5,
                }}
              ></img>
            </button>
          </a>
        </div>

        <p style={errorStyle}>
          <i>{error}</i>
        </p>
      </div>
    </div>
  );
}

const inputStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "5px",
  margin: 0,
  padding: 5,
  border: "1px solid #ccc",
  fontFamily: "Gotham, sans-serif",
  fontWeight: "lighter",
  fontSize: "12px",
  opacity: "0.85",
};

const buttonStyle = {
  color: "white",
  backgroundColor: "#EC2383",
  fontSize: 16,
  width: "100%",
  height: "100%",
  border: "none",
  borderRadius: 10,
  fontFamily: "Gotham, sans-serif",
  fontWeight: "lighter",
};

const errorStyle = {
  fontFamily: "Gotham, sans-serif",
  fontWeight: "lighter",
  fontSize: "12px",
  marginTop: 10,
};

export default PurchaseModuleSimple;
