import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";

import s_1 from "../../images/landingpage/s_1.png";
import s_2 from "../../images/landingpage/s_2.png";
import s_3 from "../../images/landingpage/s_3.png";
import s_4 from "../../images/landingpage/s_4.png";
import s_5 from "../../images/landingpage/s_5.png";
import s_6 from "../../images/landingpage/s_6.png";

const images = [s_1, s_2, s_3, s_4, s_5, s_6]; // Array of image imports

const GalleryInsert = () => {
  return (
    <Gallery
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {images.map((src, index) => (
        <Item original={src} thumbnail={src} width="600" height="600" key={index}>
          {({ ref, open }) => (
            <img
              ref={ref}
              onClick={open}
              src={src}
              style={{ width: "16.66%" }}
              alt={`Gallery item ${index + 1}`}
            />
          )}
        </Item>
      ))}
    </Gallery>
  );
};

export default GalleryInsert;
